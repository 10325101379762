.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* CSS to style the Card component */
.Card {
  min-width: 275px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
}

/* CSS to style both vertical and horizontal scrollbars */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(149, 153, 151);
  border-radius: 10px;
  transition: opacity 0.3s ease-in-out;
}

/* Style for the vertical scrollbar */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Style for the horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #555;
}

/* Hide the scrollbar after scrolling */
.Card.scrolling::-webkit-scrollbar-thumb {
  opacity: 0;
}
